import React from 'react'

import Layout from '../components/Layout'

import "../css/main.sass"
import style from "../css/pages-css/about.module.sass"

export default () => (
  <Layout>
    <main className={style.main + " standard-page"}>
      <section className={style.content}>
        <h1 className="fw-black big">About</h1>
        <h2>Me</h2>
        <p>Hi, My name is Pietro Cau, I am 17 years old and I live in Rome, Italy.<br/>
          These are a couple of things you should know about me.<br/>
          First of all, English is not my first language so please bear with me if you see some horrible grammar.<br/>
          Secondly, I am not a designer and I am not a developer (yet), but I have a great interest for both of those subjects and many more, and this brings me to my next topic.</p>
        <h2>This Website</h2>
        <p>Here I will report my (possibly wrong) opinions, thoughts, and observations about design, code and everything else I feel like exploring.<br/>
          I will also use this site to present and critique my own projects and, most importantly, I will use it as a learning experience.</p>
      </section>
    </main>
  </Layout>
)
